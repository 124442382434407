import { Link } from "react-router-dom";
import { articleEntity } from "../../entities/articleEntity"
import { convertDate } from "../../utils/dateExtension";
import "./card.css";

interface Props {
    article: articleEntity;
    insideText: boolean;
    showImage: boolean;
    inline: boolean;
}

export function Card(props: Props) {
    function getClass() {
        var className = "article-card";

        if(props.insideText)
            className += " inside-text";

        if(props.inline)
            className += " inline";

        return className;
    }

    return <li className={getClass()} >
        <Link to={`/posts/${props.article.url}`}>
            {props.showImage &&
                <div className="article-card-image">
                    <img src={props.article.urlImg} title={props.article.title} alt={props.article.title} />
                </div>
            }
            <div className="article-card-content">
                {props.showImage ? 
                    <h2 className="article-card-title">{props.article.title}</h2>
                    :
                    <h3 className="article-card-title">{props.article.title}</h3>
                }
                <p className="article-card-date">{convertDate(props.article.createdAt)}</p>
            </div>
        </Link>
    </li >
}
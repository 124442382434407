import { useEffect } from "react"

interface Props {
    handleMetaTags(title?: string, description?: string, imageUrl?: string, url?: string): void;
}

export function NotFound(props: Props) {
    useEffect(() => {
        window.scroll(0, 0);
        props.handleMetaTags("Página não encontrada - Mexeriquei", "Oops! Parece que você encontrou um caminho que não leva a lugar nenhum. A página que você está procurando pode ter sido removida, renomeada ou está temporariamente indisponível.");
    }, []);

    return <div className="info-content content-center">
        <div className="container">
            <h2>Página Não Encontrada</h2>

            <p>Oops! Parece que você encontrou um caminho que não leva a lugar nenhum. A página que você está procurando pode ter sido removida, renomeada ou está temporariamente indisponível.</p>
            <p>Não se preocupe, você ainda pode explorar nosso site e descobrir notícias emocionantes sobre suas celebridades favoritas. Aqui estão alguns links que podem te interessar:</p>
            <p>Você pode entrar em contato conosco por e-mail ou telefone através dos canais abaixo. Prometemos responder o mais rápido possível.</p>

            <ul className="pointer">
                <li>
                    <a href="/categorias/Últimas">
                        <strong>Últimas Notícias</strong>
                    </a>
                </li>
                <li>
                    <a href="/categorias/Em alta">
                        <strong>Em alta</strong>
                    </a>
                </li>
                <li>
                    <a href="/categorias/TV">
                        <strong>TV</strong>
                    </a>
                </li>
                <li>
                    <a href="/categorias/Famosos">
                        <strong>Famosos</strong>
                    </a>
                </li>
                <li>
                    <a href="/categorias/Mundo">
                        <strong>Mundo</strong>
                    </a>
                </li>
                <li>
                    <a href="/horoscopo">
                        <strong>Horóscopo</strong>
                    </a>
                </li>
            </ul>

            <p>Se você acredita que encontrou esta página por engano ou se precisar de ajuda para encontrar algo específico, não hesite em nos contatar através de nossos canais. Estamos aqui para ajudar!</p>
            <p>Agradecemos pela sua visita e esperamos que encontre o que procura em nosso site.</p>

            <p>Atenciosamente,<br />Equipe <strong>Mexeriquei</strong>.</p>
        </div>
    </div>
}
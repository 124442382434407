import { useEffect } from "react";

interface Props {
    handleMetaTags(title?: string, description?: string, imageUrl?: string, url?: string): void;
}

export function About(props: Props) {
    useEffect(() => {
        window.scroll(0, 0);
        props.handleMetaTags(
            "Sobre - Mexeriquei", 
            "Nosso objetivo é manter você atualizado com as últimas notícias, tendências e acontecimentos emocionantes do universo das celebridades.",
        );
    }, []);

    return <div className="info-content content-center">
        <div className="container">
            <h2>Sobre a Mexeriquei</h2>
            <p>Bem-vindo a Mexeriquei, sua fonte confiável de notícias e fofocas sobre o mundo dos famosos!</p>

            <p><strong>Missão</strong></p>
            <p>Nosso objetivo é manter você atualizado com as últimas notícias, tendências e acontecimentos emocionantes do universo das celebridades. Desde notícias exclusivas sobre filmes e música até os bastidores das premiações mais glamorosas, estamos aqui para trazer tudo isso para você de forma divertida e informativa.</p>

            <p><strong>O que Oferecemos</strong></p>

            <ul className="pointer">
                <li>
                    <strong>Notícias Exclusivas:</strong> Mantenha-se informado sobre as últimas notícias envolvendo suas celebridades favoritas.
                </li>
                <li>
                    <strong>Fofocas dos Bastidores:</strong> Descubra os segredos e curiosidades por trás das câmeras e dos palcos.

                </li>
                <li>
                    <strong>Entrevistas Exclusivas:</strong> Leia entrevistas exclusivas com as estrelas do cinema, música, TV e moda.
                </li>
                <li>
                    <strong>Análises e Opiniões:</strong> Explore análises aprofundadas sobre tendências, moda e cultura pop.
                </li>
            </ul>

            <p><strong>Nossa Equipe</strong></p>
            <p>Contamos com uma equipe apaixonada e dedicada, comprometida em trazer conteúdos interessantes e de qualidade para os nossos leitores. Com jornalistas experientes e especialistas em entretenimento, estamos sempre em busca das histórias mais envolventes e impactantes.</p>

            {/* TODO: resolver esta parte dos links aqui */}
            {/*<p><strong>Como nos Conectar</strong></p>
            <p>Siga-nos nas redes sociais para não perder nenhuma novidade e para participar de discussões animadas sobre o mundo dos famosos:</p>

            <ul className="pointer">
                <li>
                    <strong>Facebook:</strong> [Link para a página do Facebook]
                </li>
                <li>
                    <strong>Instagram:</strong> [Link para o Instagram]
                </li>
                <li>
                    <strong>Twitter:</strong> [Link para o Twitter]
                </li>
            </ul> */}

            <p><strong>Entre em Contato</strong></p>
            <p>Se você tiver alguma dúvida, sugestão ou deseja entrar em contato conosco para parcerias e colaborações, não hesite em nos enviar uma mensagem através de nossos canais.</p>

            <p><strong>Obrigado por nos Escolher!</strong></p>
            <p>Agradecemos por fazer parte da nossa comunidade de leitores <em>"Mexeriqueiros"</em>. Estamos ansiosos para compartilhar as últimas notícias e fofocas com você!</p>

            <p>Atenciosamente,<br />Equipe <strong>Mexeriquei</strong>.</p>
        </div>
    </div>
}
import { useEffect, useState } from "react"
import { GetByFilter } from "../../Api";
import { Card } from "../../components/card/card";
import { articleEntity } from "../../entities/articleEntity";
import { ArticleFilter } from "../../entities/articleFilter";

interface Props {
    showLoading(value: boolean): void;
    handleMetaTags(title?: string, description?: string, imageUrl?: string, url?: string): void;
    mostReadArticles: articleEntity[];
}

export function Home(props: Props) {
    const [topArticleList, setTopArticleList] = useState<articleEntity[]>([]);
    const [latestArticleList, setLatestArticleList] = useState<articleEntity[]>([]);
    const [mostReadArticleList, setMostReadArticleList] = useState<articleEntity[]>([]);
    const [tvArticleList, setTVArticleList] = useState<articleEntity[]>([]);
    const [famousArticleList, setFamousArticleList] = useState<articleEntity[]>([]);
    const [worldArticleList, setworldArticleList] = useState<articleEntity[]>([]);

    useEffect(() => {
        props.handleMetaTags();
        getTopArticles();
        getLatest();
        mostRead();
        getTvArticles();
        getFamous();
        getInternationals();
    }, []);

    async function getTopArticles() {
        props.showLoading(true);
        const result = await GetByFilter({ topArticles: true, limit: 6 } as ArticleFilter);
        props.showLoading(false);
        setTopArticleList(result);
    }

    async function getLatest() {
        const result = await GetByFilter({ latest: true, limit: 3 } as ArticleFilter);
        setLatestArticleList(result);
    }

    async function mostRead() {
        // const result = await GetByFilter({ mostRead: true, limit: 5 } as ArticleFilter);
        // setMostReadArticleList(result);
    }

    async function getTvArticles() {
        const result = await GetByFilter({ category: "TV", limit: 3 } as ArticleFilter);
        setTVArticleList(result);
    }

    async function getFamous() {
        const result = await GetByFilter({ category: "Famosos", limit: 2 } as ArticleFilter);
        setFamousArticleList(result);
    }

    async function getInternationals() {
        const result = await GetByFilter({ category: "Mundo", limit: 3 } as ArticleFilter);
        setworldArticleList(result);
    }

    return <>
        <div className="inline-article-list content-center pt-40">
            <div className="container">
                <ul className="article-list">
                    {topArticleList.slice(0, 2).map((article, index) => (
                        <div className="column col-6" key={index}>
                            <Card article={article} insideText={true} showImage={true} inline={false} />
                        </div>
                    ))}
                    {topArticleList.slice(2, 6).map((article, index) => (
                        <div className="column col-3" key={index}>
                            <Card article={article} insideText={false} showImage={true} inline={false} />
                        </div>
                    ))}
                </ul>
            </div>
        </div>
        <div className="article-search-result content-center">
            <div className="container">
                <div className="column-list">
                    <div className="column search-result-column">
                        <p className="article-list-title">ÚLTIMAS NOTÍCIAS</p>
                        <ul className="article-list">
                            {latestArticleList.map((article, index) => (
                                <Card article={article} insideText={false} showImage={true} inline={true} key={index} />
                            ))}
                        </ul>
                    </div>
                    <div className="column most-read-column">
                        <p className="article-list-title">MAIS LIDAS</p>
                        <ul className="article-list">
                            {props.mostReadArticles.map((article, index) => (
                                <Card article={article} insideText={false} showImage={false} inline={true} key={index} />
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div className="inline-article-list content-center">
            <div className="container">
                <p className="article-list-title">TV</p>
                <ul className="article-list">
                    {tvArticleList.map((article, index) => (
                        <div className="column col-4" key={index}>
                            <Card article={article} insideText={false} showImage={true} inline={false} />
                        </div>
                    ))}
                </ul>
            </div>
        </div>
        <div className="inline-article-list content-center">
            <div className="container">
                <p className="article-list-title">FAMOSOS</p>
                <ul className="article-list">
                    {famousArticleList.map((article, index) => (
                        <div className="column col-6" key={index}>
                            <Card article={article} insideText={true} showImage={true} inline={false} />
                        </div>
                    ))}
                </ul>
            </div>
        </div>
        <div className="inline-article-list content-center">
            <div className="container">
                <p className="article-list-title">MUNDO</p>
                <ul className="article-list">
                    {worldArticleList.map((article, index) => (
                        <div className="column col-4" key={index}>
                            <Card article={article} insideText={false} showImage={true} inline={false} />
                        </div>
                    ))}
                </ul>
            </div>
        </div>
    </>
}
import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import { Loading } from './components/loading/loading';
import { Header } from './components/header/header';
import { Home } from './pages/home/home';
import { Horoscope } from './pages/horoscope/horoscope';
import { ArticleList } from './pages/articleList/articleList';
import { Footer } from './components/footer/footer';
import { Article } from './pages/article/article';
import { SearchType } from './entities/searchType';
import { GetByFilter } from './Api';
import { articleEntity } from './entities/articleEntity';
import { ArticleFilter } from './entities/articleFilter';
import { NotFound } from './pages/notFound/notFound';
import { Contact } from './pages/Contact/contact';
import { About } from './pages/about/about';
import { Policy } from './pages/policy/policy';
import { Terms } from './pages/terms/terms';

const DEFAULT_TITLE = "Mexeriquei - Notícias, fofocas, horóscopo e muito mais";
const DEFAULT_DESCRIPTION = "Descubra os segredos mais quentes das celebridades: fofocas fresquinhas, notícias exclusivas, novelas, horóscopo e tudo sobre o mundo das estrelas, só aqui!";
const DEFAULT_IMAGE_URL = "https://mexeriquei.com.br/favicon.png";

function App(props: any) {
    const categories = ["Em alta", "Últimas", "TV", "Famosos", "Mundo"];
    const [showLoading, setShowLoading] = useState(false);
    const [mostReadArticleList, setMostReadArticleList] = useState<articleEntity[]>([]);

    useEffect(() => {
        getBySearchName();
    }, [window.location.href]);

    async function getBySearchName() {
        const result = await GetByFilter({ mostRead: true, limit: 5 } as ArticleFilter);
        setMostReadArticleList(result);
    }

    async function handleMetaTags(
            title: string = DEFAULT_TITLE, 
            description: string = DEFAULT_DESCRIPTION, 
            imageUrl: string = DEFAULT_IMAGE_URL,
            url: string
        ) {
        const canonicalLink = document.querySelector("link[rel='canonical']");
        const principalTitle = document.querySelector("title");
        const ogTitle = document.querySelector("meta[property='og:title']");
        const ogDescription = document.querySelector("meta[property='og:description']");
        const ogImage = document.querySelector("meta[property='og:image']");
        const ogUrl = document.querySelector("meta[property='og:url']");
        const twitterTitle = document.querySelector("meta[name='twitter:title']");
        const twitterDescription = document.querySelector("meta[name='twitter:description']");
        const twitterImage = document.querySelector("meta[name='twitter:image']");
        const twitterUrl = document.querySelector("meta[name='twitter:url']");

        if (canonicalLink) canonicalLink.setAttribute("href", url ?? window.location.href);
        if (principalTitle) principalTitle.innerHTML = title;
        if (ogTitle) ogTitle.setAttribute("content", title);
        if (ogDescription) ogDescription.setAttribute("content", description);
        if (ogImage) ogImage.setAttribute("content", imageUrl);
        if (ogUrl) ogUrl.setAttribute("content", url ?? window.location.href);
        if (twitterTitle) twitterTitle.setAttribute("content", title);
        if (twitterDescription) twitterDescription.setAttribute("content", description);
        if (twitterImage) twitterImage.setAttribute("content", imageUrl);
        if (twitterUrl) twitterUrl.setAttribute("content", url ?? window.location.href);
    }

    return (
        <div>
            <BrowserRouter>
                <Header categories={categories} />
                <Routes>
                    <Route path="/" element={
                        <Home
                            {...props}
                            showLoading={setShowLoading}
                            mostReadArticles={mostReadArticleList}
                            handleMetaTags={handleMetaTags} />
                    } />
                    <Route path="/categorias/:id" element={
                        <ArticleList
                            {...props}
                            showLoading={setShowLoading}
                            searchType={SearchType.Category}
                            mostReadArticles={mostReadArticleList}
                            handleMetaTags={handleMetaTags} />
                    } />
                    <Route path="/pesquisa/:id" element={
                        <ArticleList
                            {...props}
                            showLoading={setShowLoading}
                            searchType={SearchType.Search}
                            mostReadArticles={mostReadArticleList}
                            handleMetaTags={handleMetaTags} />
                    } />
                    <Route path="/posts/:id" element={
                        <Article
                            {...props}
                            showLoading={setShowLoading}
                            handleMetaTags={handleMetaTags} />
                    } />
                    <Route path="/horoscopo" element={
                        <Horoscope
                            {...props}
                            showLoading={setShowLoading}
                            handleMetaTags={handleMetaTags} />
                    } />
                    <Route path="/contato" element={
                        <Contact {...props} handleMetaTags={handleMetaTags} />
                    } />
                    <Route path="/sobre" element={
                        <About {...props} handleMetaTags={handleMetaTags} />
                    } />
                    <Route path="/politicas-de-privacidade" element={
                        <Policy {...props} handleMetaTags={handleMetaTags} />
                    } />
                    <Route path="/termos-de-uso" element={
                        <Terms {...props} handleMetaTags={handleMetaTags} />
                    } />
                    <Route path="/nao-encontrado" element={
                        <NotFound {...props} handleMetaTags={handleMetaTags} />
                    } />
                    <Route path="" element={
                        <NotFound {...props} handleMetaTags={handleMetaTags} />
                    } />
                    <Route path="*" element={
                        <NotFound {...props} handleMetaTags={handleMetaTags} />
                    } />
                    <Route element={
                        <NotFound {...props} handleMetaTags={handleMetaTags} />
                    } />
                </Routes>
                <Footer categories={categories} />
                <Loading show={showLoading} />
            </BrowserRouter>
        </div>
    );
}

export default App;

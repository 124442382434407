import { useEffect, useState } from "react"
import "./horoscope.css"
import { GetHoroscope } from "../../Api";
import { HoroscopeEntity } from "../../entities/horoscopeEntity";

interface Props {
    showLoading(value: boolean): void;
    handleMetaTags(title?: string, description?: string, imageUrl?: string, url?: string): void;
}

export function Horoscope(props: Props) {
    const [sign, setSign] = useState("");
    const [checkType, setCheckType] = useState("");
    const [showDailyFields, setShowDailyFields] = useState(false);
    const [dailyOption, setDailyOption] = useState("today");
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [showResult, setShowResult] = useState(false);
    const [result, setResult] = useState<HoroscopeEntity>();

    useEffect(() => {
        props.showLoading(false);
        props.handleMetaTags("Horóscopo - Mexeriquei", "Escolha seu signo, e tenha informações diárias do seu horóscopo a qualquer hora e em qualquer lugar, vem conferir!");
    }, []);

    function chooseSign(value: string) {
        setSign(value);
        setCheckType("");
        setShowDailyFields(false);
        setDailyOption("today");

        setIsButtonDisabled(true);
    }

    function chooseCheckType(value: string) {
        setCheckType(value);
        setShowDailyFields(value === "daily");
        setDailyOption("today");

        setIsButtonDisabled(false);
    }

    async function handleHoroscope() {
        props.showLoading(true);

        if (sign.length === 0 || checkType.length === 0) {
            chooseSign("");
            return;
        }

        var result: HoroscopeEntity | null = null;
        if (dailyOption.length > 0)
            result = await GetHoroscope(sign, checkType, dailyOption);
        else
            result = await GetHoroscope(sign, checkType);

        props.showLoading(false);

        if (!result) {
            return;
        }

        setResult(result);
        setShowResult(true);
    }

    function renderResult() {
        let type = window.document.querySelector(".sign-check-type.active")?.innerHTML.toLocaleLowerCase();
        const select = document.querySelector("select");

        if (type === "diário")
            type = "de " + select?.options[select.selectedIndex].text;

        return showResult && result && <>
            <div className="horoscope-title">
                <h3>Seu horóscopo {type}</h3>
            </div>
            <div className="horoscope-content">
                <p>{result.horoscopeData}</p>
            </div>

            {result.standoutDays && <>
                <div className="horoscope-title">
                    <h3>Dias de destaque</h3>
                </div>
                <div className="horoscope-content">
                    <p>{result.standoutDays}</p>
                </div>
            </>}
            {result.challengingDays && <>
                <div className="horoscope-title">
                    <h3>Dias de desafio</h3>
                </div>
                <div className="horoscope-content">
                    <p>{result.challengingDays}</p>
                </div>
            </>}
        </>
    }

    return <div className="article-search-result content-center">
        <div className="container">
            <div className="horoscope-area">
                <h2>Horóscopo</h2>
                {!showResult &&
                    <form className="horoscope-form">
                        <div className="form-field">
                            <div className="field-description">
                                <label>Escolha seu signo</label>
                            </div>
                            <div className="field-options signs">
                                <button
                                    className={sign === "aries" ? "sign-button active" : "sign-button"}
                                    type="button"
                                    value="aries"
                                    onClick={() => chooseSign("aries")}
                                >
                                    <img src="/icons/aries.png" alt="" />
                                    Áries
                                </button>
                                <button
                                    className={sign === "taurus" ? "sign-button active" : "sign-button"}
                                    type="button"
                                    value="taurus"
                                    onClick={() => chooseSign("taurus")}
                                >
                                    <img src="/icons/touro.png" alt="" />
                                    Touro
                                </button>
                                <button
                                    className={sign === "gemini" ? "sign-button active" : "sign-button"}
                                    type="button"
                                    value="gemini"
                                    onClick={() => chooseSign("gemini")}
                                >
                                    <img src="/icons/gemeos.png" alt="" />
                                    Gêmeos
                                </button>
                                <button
                                    className={sign === "cancer" ? "sign-button active" : "sign-button"}
                                    type="button"
                                    value="cancer"
                                    onClick={() => chooseSign("cancer")}
                                >
                                    <img src="/icons/cancer.png" alt="" />
                                    Câncer
                                </button>
                                <button
                                    className={sign === "leo" ? "sign-button active" : "sign-button"}
                                    type="button"
                                    value="leo"
                                    onClick={() => chooseSign("leo")}
                                >
                                    <img src="/icons/leao.png" alt="" />
                                    Leão
                                </button>
                                <button
                                    className={sign === "virgo" ? "sign-button active" : "sign-button"}
                                    type="button"
                                    value="virgo"
                                    onClick={() => chooseSign("virgo")}
                                >
                                    <img src="/icons/virgem.png" alt="" />
                                    Virgem
                                </button>
                                <button
                                    className={sign === "libra" ? "sign-button active" : "sign-button"}
                                    type="button"
                                    value="libra"
                                    onClick={() => chooseSign("libra")}
                                >
                                    <img src="/icons/libra.png" alt="" />
                                    Libra
                                </button>
                                <button
                                    className={sign === "scorpio" ? "sign-button active" : "sign-button"}
                                    type="button"
                                    value="scorpio"
                                    onClick={() => chooseSign("scorpio")}
                                >
                                    <img src="/icons/escorpiao.png" alt="" />
                                    Escorpião
                                </button>
                                <button
                                    className={sign === "sagittarius" ? "sign-button active" : "sign-button"}
                                    type="button"
                                    value="sagittarius"
                                    onClick={() => chooseSign("sagittarius")}
                                >
                                    <img src="/icons/sagitario.png" alt="" />
                                    Sagitário
                                </button>
                                <button
                                    className={sign === "capricorn" ? "sign-button active" : "sign-button"}
                                    type="button"
                                    value="capricorn"
                                    onClick={() => chooseSign("capricorn")}
                                >
                                    <img src="/icons/capricornio.png" alt="" />
                                    Capricórnio
                                </button>
                                <button
                                    className={sign === "aquarius" ? "sign-button active" : "sign-button"}
                                    type="button"
                                    value="aquarius"
                                    onClick={() => chooseSign("aquarius")}
                                >
                                    <img src="/icons/aquario.png" alt="" />
                                    Aquário
                                </button>
                                <button
                                    className={sign === "pisces" ? "sign-button active" : "sign-button"}
                                    type="button"
                                    value="pisces"
                                    onClick={() => chooseSign("pisces")}
                                >
                                    <img src="/icons/peixes.png" alt="" />
                                    Peixes
                                </button>
                            </div>
                        </div>
                        <div className="form-field">
                            <div className="field-description">
                                <label>Como deseja consultar?</label>
                            </div>
                            <div className="field-options">
                                <button
                                    className={checkType === "daily" ? "sign-check-type active" : "sign-check-type"}
                                    type="button"
                                    value="daily"
                                    onClick={() => chooseCheckType("daily")}
                                >
                                    Diário
                                </button>
                                <button
                                    className={checkType === "weekly" ? "sign-check-type active" : "sign-check-type"}
                                    type="button"
                                    value="weekly"
                                    onClick={() => chooseCheckType("weekly")}
                                >
                                    Semanal
                                </button>
                                <button
                                    className={checkType === "monthly" ? "sign-check-type active" : "sign-check-type"}
                                    type="button"
                                    value="monthly"
                                    onClick={() => chooseCheckType("monthly")}
                                >
                                    Mensal
                                </button>
                            </div>
                            <select
                                className={showDailyFields ? "field-options daily-fields show" : "field-options daily-fields"}
                                value={dailyOption}
                                onChange={(event) => setDailyOption(event.target.value)}
                            >
                                <option value="today">Hoje</option>
                                <option value="tomorrow">Amanhã</option>
                                <option value="yesterday">Ontem</option>
                            </select>
                        </div>
                    </form>
                }
                <div className={showResult ? "horoscope-result show" : "horoscope-result"}>
                    {renderResult()}
                </div>
                <div className="form-field">
                    <div className="horoscope-footer-buttons">
                        {!showResult &&
                            <button className="btn-get-horoscope" type="button" disabled={isButtonDisabled} onClick={() => handleHoroscope()}>
                                <i className="material-symbols-outlined">
                                    search
                                </i>
                                Consultar
                            </button>
                        }

                        <button className={showResult ? "btn-get-new-check show" : "btn-get-new-check"} type="button" onClick={() => window.location.href = "/horoscopo"}>
                            <i className="material-symbols-outlined">
                                autorenew
                            </i>
                            Nova consulta
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div >
}
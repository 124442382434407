import { useEffect } from "react";

interface Props {
    handleMetaTags(title?: string, description?: string, imageUrl?: string, url?: string): void;
}

export function Policy(props: Props) {
    useEffect(() => {
        window.scroll(0, 0);
        props.handleMetaTags("Política de Privacidade - Mexeriquei", "A sua privacidade é importante para nós. Esta política de privacidade explica como a Mexeriquei coleta, utiliza e protege as informações pessoais que você fornece ao usar nosso site.");
    }, []);

    return <div className="info-content content-center">
        <div className="container">
            <h2>Política de Privacidade e Uso de Cookies</h2>
            <p>A sua privacidade é importante para nós. Esta política de privacidade explica como a Mexeriquei coleta, utiliza e protege as informações pessoais que você fornece ao usar nosso site.</p>

            <p><strong>Informações Coletadas</strong></p>
            <p>Quando você visita a Mexeriquei, podemos coletar informações pessoais como seu nome, endereço de e-mail e outras informações que você nos fornecer voluntariamente através de formulários de contato, assinaturas de boletim informativo ou ao comentar em nossos artigos.</p>
            <p>Além disso, nosso site utiliza cookies para melhorar sua experiência de navegação. Os cookies são pequenos arquivos de texto que são armazenados no seu dispositivo quando você visita um site. Eles nos ajudam a analisar o tráfego do site, personalizar conteúdos e anúncios, além de oferecer recursos de mídia social.</p>

            <p><strong>Uso de Informações</strong></p>
            <p>As informações que coletamos são utilizadas para os seguintes propósitos:</p>

            <ul className="numeric">
                <li>Fornecer conteúdos relevantes e personalizados.</li>
                <li>Enviar notificações sobre novos artigos, atualizações ou promoções, se você optar por receber nossos e-mails.</li>
                <li>Melhorar continuamente nosso site e serviços com base no feedback e comportamento dos usuários.</li>
                <li>Proteger a segurança e integridade do nosso site.</li>
            </ul>

            <p><strong>Compartilhamento de Informações</strong></p>
            <p>Não compartilhamos suas informações pessoais com terceiros, a menos que seja necessário para cumprir com obrigações legais ou com o seu consentimento explícito.</p>

            <p><strong>Gerenciamento de Cookies</strong></p>
            <p>Você pode aceitar ou recusar o uso de cookies em nosso site através das configurações do seu navegador. Por favor, note que a recusa de cookies pode limitar algumas funcionalidades do site.</p>

            <p><strong>Links para Sites de Terceiros</strong></p>
            <p>Nosso site pode conter links para sites de terceiros. Não nos responsabilizamos pela política de privacidade ou conteúdo desses sites. Recomendamos que você revise as políticas de privacidade desses sites antes de fornecer qualquer informação pessoal.</p>

            <p><strong>Alterações na Política de Privacidade</strong></p>
            <p>Reservamo-nos o direito de modificar esta política de privacidade a qualquer momento. Quaisquer alterações serão publicadas nesta página.</p>
            <p>Ao utilizar a Mexeriquei, você concorda com nossa política de privacidade e uso de cookies. Se tiver alguma dúvida ou preocupação, entre em contato conosco através dos meios disponibilizados na seção de contato.</p>

            <p><strong>Obrigado por confiar em nós.</strong></p>

            <p>Atenciosamente,<br />Equipe <strong>Mexeriquei</strong>.</p>
        </div>
    </div>
}
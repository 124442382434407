import { articleEntity } from "./entities/articleEntity";
import { ArticleFilter } from "./entities/articleFilter";
import { HoroscopeEntity } from "./entities/horoscopeEntity";

const API_URL = "https://mexeriquei.com.br/api";

export async function GetByFilter(filter: ArticleFilter): Promise<Array<articleEntity>> {
    var requestFilter: any = {
        limit: filter.limit ?? 1
    }

    if (filter.topArticles) requestFilter.topArticles = filter.topArticles;
    if (filter.latest) requestFilter.latest = filter.latest;
    if (filter.mostRead) requestFilter.mostRead = filter.mostRead;
    if (filter.category) requestFilter.category = filter.category;
    if (filter.searchText) requestFilter.searchText = filter.searchText;
    if (filter.page || filter.page === 0) requestFilter.page = filter.page;

    const query = new URLSearchParams(requestFilter).toString();
    return await fetch(`${API_URL}/articles?${query}`).then(x=> x.status === 200 ? x.json() : []);
}

export async function GetByUrl(url: string): Promise<articleEntity> {
    return await fetch(`${API_URL}/articles/${url}`).then(x=> x.status === 200 ? x.json() : null);
}

export async function GetHoroscope(sign: string, checkType: string, dailyType: string | null = null): Promise<HoroscopeEntity> {
    let query = dailyType ? `&day=${dailyType}` : "";
    return await fetch(`${API_URL}/horoscope?type=${checkType}&sign=${sign}${query}`).then(x => x.status === 200 ? x.json() : null);
}

import { useEffect, useState } from "react"
import { articleEntity } from "../../entities/articleEntity";
import { useParams } from "react-router-dom";
import { GetByUrl } from "../../Api";
import { Link } from "react-router-dom";
import { convertDate } from "../../utils/dateExtension";
import { useNavigate } from "react-router-dom";
import "./article.css";

interface Props {
    showLoading(value: boolean): void;
    handleMetaTags(title?: string, description?: string, imageUrl?: string, url?: string): void;
}

interface Params {
    id: string;
}

export function Article(props: Props) {
    const navigate = useNavigate();
    const [article, setArticle] = useState<articleEntity>();
    const params = useParams<keyof Params>() as Params;

    useEffect(() => {
        window.scroll(0, 0);
        props.showLoading(false);
        GetArticle();
    }, []);

    async function GetArticle() {
        var result = await GetByUrl(params.id);
        if (!result) {
            navigate("/nao-encontrado");
            return;
        }

        props.handleMetaTags(
            result.title,
            result.subTitle,
            result.urlImg,
            `https://mexeriquei.com.br/posts/${result.url}`
        );
        setArticle(result);
    }

    return <div className="article content-center">
        <div className="container">
            {article &&
                <div className="article-content">
                    <div className="article-header">
                        <p className="article-tag">{article.category}</p>
                        <h1 className="article-title">{article.title}</h1>
                        <h2 className="article-subtitle">{article.subTitle}</h2>
                        <div className="article-info">
                            <p className="article-author">Por {article.author}</p>
                            <p className="article-date">{convertDate(article.createdAt)}. Atualizado em {convertDate(article.updatedAt)}</p>
                        </div>
                        <div className="article-banner">
                            <img src={article.urlImg} title={article.title} alt={article.title} />
                        </div>
                    </div>
                    <div className="article-body" dangerouslySetInnerHTML={{ __html: article.content }}>
                        {/* ... */}
                    </div>
                    <div className="article-footer">
                        <div className="article-related">
                            <p className="article-related-title">Assuntos Relacionados</p>
                            <div className="article-related-tags">
                                <Link to="/categorias/Famosos">Famosos</Link>
                                <Link to="/categorias/TV">TV</Link>
                                <Link to="/categorias/Mundo">Mundo</Link>
                            </div>
                        </div>
                        <div className="article-share-area">
                            <p className="article-share-title">Compartilhar</p>
                            <div className="article-share-buttons">
                                <a target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}&amp;src=sdkpreparse`} rel="noopener noreferrer">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"
                                        className="styled__Svg-sc-1j66vyb-0 fNRkt">
                                        <path
                                            d="M16.185 8.82h-2.85V6.95a.76.76 0 01.64-.86h2.16V3h-2.77a3.51 3.51 0 00-3.77 3.22 5 5 0 000 .55v2.06h-1.78v3.18h1.78v9h3.74v-9h2.52l.33-3.18v-.01z">
                                        </path>
                                    </svg></a>
                                <a target="_blank" href={`https://twitter.com/intent/tweet?url=${window.location.href}&text=${article.title}`} rel="noopener noreferrer">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"
                                        className="styled__Svg-sc-1j66vyb-0 fNRkt">
                                        <path
                                            d="M20.23 6.679a7.21 7.21 0 01-2 .56 3.51 3.51 0 001.55-2 7.18 7.18 0 01-2.24.86 3.53 3.53 0 00-6 3.22 10 10 0 01-7.34-3.65 3.54 3.54 0 001.1 4.72 3.55 3.55 0 01-1.6-.45 3.54 3.54 0 002.83 3.47 3.649 3.649 0 01-1.6.06 3.54 3.54 0 003.3 2.47A7.1 7.1 0 013 17.409a10.06 10.06 0 0015.48-8.47v-.46a7.29 7.29 0 001.76-1.83l-.01.03z">
                                        </path>
                                    </svg>
                                </a>
                                <a target="_blank" href={`https://api.whatsapp.com/send?text=${window.location.href}`} rel="noopener noreferrer">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"
                                        className="styled__Svg-sc-1j66vyb-0 fNRkt">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                            d="M19.09 4.899A9.967 9.967 0 003.412 16.922L2 22.07l5.277-1.386c1.46.8 3.099 1.22 4.763 1.22A9.966 9.966 0 0019.09 4.9zm-7.05 15.336a8.26 8.26 0 01-4.212-1.147l-.303-.193-3.139.826.817-3.056-.193-.312a8.26 8.26 0 117.03 3.882zm4.544-6.195c-.248-.128-1.469-.725-1.698-.808-.23-.082-.395-.128-.56.12-.165.248-.643.807-.79.982-.146.174-.293.183-.541.055a6.8 6.8 0 01-2-1.23 7.636 7.636 0 01-1.387-1.735c-.146-.247 0-.367.11-.504.23-.272.438-.56.625-.863a.477.477 0 000-.431c-.065-.129-.56-1.35-.771-1.836-.211-.486-.422-.404-.579-.404h-.477a.918.918 0 00-.66.312 2.753 2.753 0 00-.918 2.074 4.882 4.882 0 001.018 2.533 11.124 11.124 0 004.259 3.754c.46.202.933.376 1.413.523a3.37 3.37 0 001.57.101 2.516 2.516 0 001.68-1.184 2.01 2.01 0 00.146-1.184c-.064-.1-.23-.165-.477-.284l.037.01z">
                                        </path>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    </div>
}
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import { articleEntity } from "../../entities/articleEntity";
import { SearchType } from "../../entities/searchType";
import { GetByFilter } from "../../Api";
import { ArticleFilter } from "../../entities/articleFilter";
import { Card } from "../../components/card/card";
import "./articleList.css";

interface Props {
    showLoading(value: boolean): void;
    handleMetaTags(title?: string, description?: string, imageUrl?: string, url?: string): void;
    searchType: string;
    mostReadArticles: articleEntity[];
}

interface Params {
    id: string;
}

export function ArticleList(props: Props) {
    const [articleList, setArticleList] = useState<articleEntity[]>([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [showMoreBtnActive, setShowMoreBtnActive] = useState(true);
    const params = useParams<keyof Params>() as Params;
    useEffect(() => {
        window.scroll(0, 0);
        props.handleMetaTags(`${params.id} - Mexeriquei`);
        getContent();
    }, [window.location.href]);

    async function getContent() {
        setPageNumber(0);
        props.showLoading(true);
        if (props.searchType === SearchType.Category) {
            const result = await getByCategory(params.id, 0);
            setShowMoreBtnActive(result.length > 0);
            setArticleList(result);
            props.showLoading(false);
            return;
        }

        const result = await getBySearchName(params.id, 0);
        setShowMoreBtnActive(result.length > 0);
        setArticleList(result);
        props.showLoading(false);
    }

    async function getByCategory(categoryName: string, pageNumber: number) {
        var filter = { limit: 3, page: pageNumber } as ArticleFilter;

        if (categoryName === "Em alta")
            filter.topArticles = true;
        else if (categoryName === "Últimas")
            filter.latest = true;
        else
            filter.category = categoryName;

        return await GetByFilter(filter);
    }

    async function getBySearchName(searchText: string, pageNumber: number) {
        return await GetByFilter({ searchText: searchText, limit: 3, page: pageNumber } as ArticleFilter);
    }

    async function showMoreContent() {
        const newPageNumber = pageNumber + 1;
        props.showLoading(true);
        if (props.searchType === SearchType.Category) {
            const result = await getByCategory(params.id, newPageNumber);
            setShowMoreBtnActive(result.length > 0);

            let newList = articleList;
            setArticleList(newList.concat(result));

            setPageNumber(newPageNumber);

            props.showLoading(false);
            return;
        }

        const result = await getBySearchName(params.id, newPageNumber);
        setShowMoreBtnActive(result.length > 0);
        let newList = articleList;
        setArticleList(newList.concat(result));
        setPageNumber(newPageNumber);

        props.showLoading(false);
    }

    return <div className="article-search-result content-center">
        <div className="container">
            <div className="article-search-title">
                Resultado para: <strong>{params.id}</strong>
            </div>
            <div className="column-list">
                <div className="column search-result-column">
                    <p className="article-list-title">ÚLTIMAS NOTÍCIAS</p>
                    <ul className="article-list">
                        {articleList.map((article, index) => (
                            <Card article={article} insideText={false} showImage={true} inline={true} key={index} />
                        ))}
                    </ul>
                    {showMoreBtnActive && <button className="show-more-button" onClick={() => showMoreContent()}>Ver mais notícias</button>}
                </div>
                <div className="column most-read-column">
                    <p className="article-list-title">MAIS LIDAS</p>
                    <ul className="article-list">
                        {props.mostReadArticles.map((article, index) => (
                            <Card article={article} insideText={false} showImage={false} inline={true} key={index} />
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    </div>
}
import { useEffect } from "react";

interface Props {
    handleMetaTags(title?: string, description?: string, imageUrl?: string, url?: string): void;
}

export function Terms(props: Props) {
    useEffect(() => {
        window.scroll(0, 0);
        props.handleMetaTags("Termos de Uso - Mexeriquei", "A Mexeriquei fornece conteúdos informativos e notícias sobre diversos tópicos. Nosso objetivo é oferecer informações precisas e atualizadas, porém não nos responsabilizamos por qualquer imprecisão ou incompletude do conteúdo publicado.");
    }, []);

    return <div className="info-content content-center">
        <div className="container">
            <h2>Termos de Uso</h2>
            <p>Bem-vindo a Mexeriquei! Ao acessar e usar nosso site, você concorda em cumprir estes termos de uso. Leia atentamente as seguintes informações antes de continuar a usar nosso site.</p>

            <p><strong>1. Conteúdo do Site</strong></p> 
            <p>A Mexeriquei fornece conteúdos informativos e notícias sobre diversos tópicos. Nosso objetivo é oferecer informações precisas e atualizadas, porém não nos responsabilizamos por qualquer imprecisão ou incompletude do conteúdo publicado.</p>

            <p><strong>2. Uso Permitido</strong></p>
            <p>Você pode acessar e visualizar o conteúdo da Mexeriquei para fins pessoais e não comerciais. Você não tem permissão para copiar, modificar, distribuir ou usar nosso conteúdo para fins comerciais sem autorização prévia.</p>
            
            <p><strong>3. Comentários e Interação</strong></p>
            <p>Encorajamos a participação dos nossos leitores através de comentários em nossos artigos. No entanto, reservamo-nos o direito de moderar e remover qualquer conteúdo que seja considerado inadequado, ofensivo, difamatório ou que viole os direitos de terceiros.</p>

            <p><strong>4. Propriedade Intelectual</strong></p>
            <p>Todos os direitos autorais e propriedade intelectual relacionados a Mexeriquei, incluindo textos, imagens, vídeos e logotipos, são de propriedade exclusiva da Mexeriquei ou de seus licenciadores. Qualquer uso não autorizado desses materiais é estritamente proibido.</p>

            <p><strong>5. Links Externos</strong></p>
            <p>Nosso site pode conter links para sites de terceiros. Não nos responsabilizamos pelo conteúdo desses sites ou por qualquer dano ou prejuízo causado pelo uso desses links.</p>

            <p><strong>6. Limitação de Responsabilidade</strong></p>
            <p>A Mexeriquei não se responsabiliza por quaisquer danos diretos, indiretos, incidentais, consequenciais ou punitivos decorrentes do uso ou impossibilidade de uso do nosso site.</p>

            <p><strong>7. Alterações nos Termos de Uso</strong></p>
            <p>Reservamo-nos o direito de modificar estes termos de uso a qualquer momento. As alterações entrarão em vigor imediatamente após a publicação nesta página.</p>

            <p><strong>8. Lei Aplicável</strong></p>
            <p>Estes termos de uso são regidos pelas leis do Brasil. Qualquer disputa relacionada a estes termos será submetida à jurisdição exclusiva dos tribunais competentes do Brasil.</p>
            <p>Ao continuar a usar a Mexeriquei, você concorda com estes termos de uso. Se tiver alguma dúvida ou preocupação, entre em contato conosco através dos meios disponibilizados na seção de contato.</p>
            
            <p><strong>Obrigado por nos Escolher!</strong></p>
            <p>Atenciosamente,<br />Equipe <strong>Mexeriquei</strong>.</p>
        </div>
    </div>
}
import { useEffect } from "react";

interface Props {
    handleMetaTags(title?: string, description?: string, imageUrl?: string, url?: string): void;
}

export function Contact(props: Props) {
    useEffect(() => {
        window.scroll(0, 0);
        props.handleMetaTags('Contato - Mexeriquei', 'Se você tiver alguma pergunta, sugestão, feedback ou apenas quiser dizer olá, não hesite em nos contatar. Estamos sempre abertos a novas ideias e prontos para ouvir o que você tem a dizer.');
    }, []);
    
    return <div className="info-content content-center">
        <div className="container">
            <h2>Olá!</h2>

            <p>Ficamos felizes em saber que você está interessado em entrar em contato conosco. Na <strong>Mexeriquei</strong>, valorizamos muito a interação com nossos leitores, parceiros e colaboradores.</p>
            <p>Se você tiver alguma pergunta, sugestão, feedback ou apenas quiser dizer olá, não hesite em nos contatar. Estamos sempre abertos a novas ideias e prontos para ouvir o que você tem a dizer.</p>
            <p>Você pode entrar em contato conosco por e-mail ou telefone através dos canais abaixo. Prometemos responder o mais rápido possível.</p>

            <p><strong>E-mail:</strong> fac@mexeriquei.com</p>
            <p><strong>Telefone:</strong> (85) 99293-8350</p>
            <p><strong>Whatsapp:</strong> (85) 99293-8350</p>

            <p>Obrigado por fazer parte da nossa comunidade de leitores <em>"Mexeriqueiros"</em>. Esperamos ter notícias suas em breve!</p>

            <p>Atenciosamente,<br />Equipe <strong>Mexeriquei</strong>.</p>
        </div>
    </div>
}